/*CSS FILE */

.header {
	top: 0;
	height: 70px;
	color: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	padding-left: 20px;
	width: 100%;
	padding-top: 10px;
}
.text_toggle {
	font-family: "Open Sans";
	font-size: 12.5px;
}
.tooltip {
	font-size: 15px !important;
	width: 250px !important;
}

.title-formula {
	color: #185894;
	font-family: "Open Sans";
	font-size: 32px;
	text-align: center;
	width: 320px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 80px;
}

.under-title-formula {
	color: #b5b5b5;
	font-family: "Open Sans";
	font-size: 17px;
	font-style: italic;
	text-align: center;
	width: 320px;
	font-weight: 300;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}

.form-big {
	margin-top: 60px;
	width: 320px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	padding-left: 45px;
}

.img-logo {
	height: 60px;
}

.img-picto {
	width: 320px;
	margin-top: 30px;
}

.div-central {
	width: 320px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 100px;
}

.input-container {
	width: 320px;
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin-bottom: 15px;
	margin-left: auto;
	margin-right: auto;
	font-family: "Open Sans";
}

.flex {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.input-file {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-start;
	width: 275px;
	font-family: "Open Sans";
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin: 0;
	padding-left: 8px;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 1px solid #c5c5c5;
	border-radius: 4px;
	margin-right: 7px;
	cursor: pointer;
}

.upload-icon {
	margin-bottom: 5px;
}

input[type="file"] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-field {
	height: 30px;
	width: 275px;
	font-family: "Open Sans";
	font-size: 15px;
	color: rgb(145, 145, 145);
	margin: 0;
	padding: 0;
	padding-left: 8px;
	border: 1px solid #c5c5c5;
	border-radius: 4px;
	margin-right: 7px;
}

.final-error {
	color: red;
	font-family: "Open Sans";
	font-size: 12.5px;
	margin-top: 10px;
	margin-bottom: 100px;
	margin-left: auto;
	margin-right: auto;
}

.errorClass {
	border: 1px solid red;
	color: red;
}

.info {
	color: #f9b208;
}

.accept-bulletin {
	width: 330;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 300;
	text-align: justify;
	line-height: 24px;
	color: rgb(145, 145, 145);
}

.bold {
	font-weight: 500;
}

.accept-bulletin-flex {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	width: 300px;
}

.error {
	outline: 1px solid red;
}

.button-submit-patient {
	background-color: #185894;
	font-family: "Open Sans";
	font-size: 18px;
	color: white;
	height: 35px;
	width: 150px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	border-color: #185894;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease; /* Add a transition for smooth color change */

	/* Add a specific rule for the disabled state */
	&:disabled {
		background-color: #999999; /* Change the background color for disabled state */
		border-color: #999999; /* Change the border color for disabled state */
		cursor: not-allowed; /* Change cursor for disabled state */
	}
}

.button-submit-secrétaire {
	background-color: #185894;
	font-family: "Open Sans";
	font-size: 18px;
	color: white;
	height: 35px;
	width: 150px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	border-color: #185894;
	border-radius: 4px;
	cursor: pointer;
}

.footer {
	height: auto;
	background-color: #e9e9e9;
	bottom: 0px;
	width: 100%;
	border-color: #000000;
	display: flex;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 20px;
}

a {
	color: #000000;
}
a:link {
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a:active {
	text-decoration: underline;
}

.inner-footer {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

.arange-map {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 30px;
	border-radius: 7px;
	overflow: hidden;
}

.img-logo-footer {
	height: 60px;
	width: 110px;
}

.title-footer {
	font-family: "Open Sans";
	font-size: 19px;
	line-height: 23px;
	font-weight: 500;
	margin-top: 30px;
}

.texte-footer {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 15px;
	line-height: 23px;
}

.texte-footer-copyright {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 11px;
	line-height: 23px;
}

.space {
	height: 30px;
}

.prescription-note {
	font-size: 14px;
	font-style: italic;
	font-weight: bold;
	line-height: normal;
	font-family: "Open Sans";
	margin-bottom: 10px; /* Adjust as needed */
	color: #666; /* Adjust as needed */
}

.address-note {
	font-size: 14px;
	font-style: italic;
	font-weight: bold;
	line-height: normal;
	font-family: "Open Sans";
	margin-bottom: 5px; /* Adjust as needed */
	margin-top: 5px;
	color: #666; /* Adjust as needed */
}

.measurement-guide {
	margin-top: 20px;
}
.measurement-image {
	width: 100%;
	text-align: left; /* Center the image */
	margin-bottom: 15px; /* Spacing between image and input fields */
}

.measurement-image img {
	max-width: 90%; /* Adjust as needed to ensure the image fits well on the screen */
	border-radius: 7px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); /* Optional shadow for emphasis */
}

.measurement-fields {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center; /* Center align the input fields */
}

.info-link {
	display: inline-block;
	padding: 8px; /* Smaller touch area as it's not a primary action */
	color: #185894; /* or another color that suits your design, but less striking */
	text-decoration: underline; /* retains the link feel */
	font-weight: normal; /* not bold since it's not a primary action */
	text-align: center;
	border-radius: 4px; /* slight rounding, less than a button */
	transition: background-color 0.3s, color 0.3s; /* Optional: for a smooth color transition effect */
}

.info-link:hover,
.info-link:focus {
	color: #002366; /* Optional: slightly darker color on hover/focus for a subtle effect */
	background-color: transparent; /* No background color change */
}

/*Home css here %*/
.simple-form-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 40vh;
	background-color: #f4f4f4;
	padding: 20px;
	padding-bottom: 30px;
}

.simple-form-container h1 {
	font-size: 24px;
	color: #333;
	margin-bottom: 20px;
}

.simple-form-buttons {
	display: flex;
	flex-direction: column; /* Buttons stacked on mobile */
	width: 100%; /* Ensure full width on mobile */
	max-width: 400px; /* Max width to keep buttons reasonable on desktop */
}

.simple-form-button {
	font-size: 16px;
	padding: 10px 20px;
	margin: 5px;
	border: none;
	border-radius: 5px;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s;
	flex-grow: 1; /* Make buttons the same size */
}

.simple-form-button:first-child {
	background-color: #185894; /* Lighter blue for the first button */
}

.simple-form-button:last-child {
	background-color: #002366; /* Darker blue for the second button */
}

.simple-form-button:hover {
	opacity: 0.9; /* Slight opacity change on hover for interaction feedback */
}

@media (min-width: 768px) {
	.simple-form-buttons {
		flex-direction: row; /* Side-by-side on desktop */
	}
}
